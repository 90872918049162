
export default {
  name: 'AtomProjectSliderThumb',
  props: {
    thumb: {
      required: true,
      type: String
    },
    title: {
      required: true,
      type: String,
      default: ''
    },
    subtitle: {
      required: true,
      type: String,
      default: ''
    },
    sold: {
      required: false,
      type: Boolean,
      default: false
    },
    thumbId: {
      required: true,
      type: String,
      default: ''
    },
    activeThumb: {
      required: true,
      type: String,
      default: 'item-0'
    }
  },
  computed: {
    isActive() {
      return this.$props.activeThumb === this.thumbId
    },
    resourceUrl() {
      return `${this.$store.getters['base/cdnBase']}/${this.$props.thumb}`
    }
  },
  methods: {
    handleClick() {
      this.$emit('change', this.thumbId)
    }
  }
}
