

import baseConstants from "~/store/base/-constants";

export default {
  name: 'AtomMobileButtonCategories',
  props: {

  },
  data() {
    return {
      isMobileView: typeof window !== 'undefined' ? window.innerWidth < 901 : false
    }
  },
  computed: {
    showCategoriesPinsMobile() {
      return this.$store.state[baseConstants.namespace].categoriesPinsMobile
    }
  },
  watch: {},
  mounted() {
    window.addEventListener('resize', this.resizeListener)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeListener)
  },
  methods: {
    toggleCategoriesPinsMobile() {
      this.$store.dispatch(baseConstants.withNamespace(baseConstants.action.TOGGLE_CATEGORIES_PINS_MOBILE))
    },
    resizeListener(e) {
      this.isMobileView = typeof window !== 'undefined' ? window.innerWidth < 901 : false
    }
  }
}
